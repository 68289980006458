import { NgModule } from '@angular/core';
import { NavigationStart, Router, RouterModule, Routes } from '@angular/router';

import { ApplicationRedirect } from './components/Application/ApplicationRedirect/app-redirect.ctrl';
import { ConnectionOAuthResponseControl } from './components/ConnectionManagement/connection-oauth-response/connection-oauth-response.ctrl';
import { CookieDisableComponent } from './components/CookieDisable/cookie-disable.component';
import { EulaManagementViewComponent } from './components/EulaManagement/eula-management.view';
import { AuthenticationComponent } from './components/PasswordManagement/Authentication/authentication.ctrl';
import { ProfileManagementViewComponent } from './components/ProfileManagement/profile-management.view';
import { HomeComponent } from './components/home/home.component';
import { LocalizationManagementViewComponent } from './components/localization/localization-management.view';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { Constants } from './constants';
import { canActivateChildGuard, canActivateGuard, canMatchGuard, canActivateCookieGuard, canActivateChildCookieGuard, canMatchCookieGuard } from './services/auth.service';
import { AuthService } from './services/auth.service';
import { Permissions } from './shared/enums/Permissions';
import { PortalOptionsComponent } from './components/PreferencesManagement/components/portal-options/portal-options.ctrl';

const appRoutes: Routes = [
    { path: 'home', redirectTo: '/', pathMatch: 'full' },
    { path: 'login', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], component: HomeComponent },
    { path: 'eula', canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard], component: EulaManagementViewComponent },
    { path: Constants.AppRedirectUrl, canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard], component: ApplicationRedirect },    
    { path: 'profile', component: ProfileManagementViewComponent, canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard], },
    { path: 'language', component: LocalizationManagementViewComponent, canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard], },
    { path: 'authentication', component: AuthenticationComponent, canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard], },
    { path: 'portal-options', component: PortalOptionsComponent, canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard], },
    { path: 'cookiedisable', component: CookieDisableComponent },
    { path: 'azureadresponse', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], component: ConnectionOAuthResponseControl },
    { path: 'gsuiteresponse', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], component: ConnectionOAuthResponseControl },
    {
        path: 'forgotpassword', canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard],
        loadChildren: () => import('./components/ForgotPassword/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        path: 'resetpassword', canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard],
        loadChildren: () => import('./components/ResetPassword/reset-password.module').then(m => m.ResetPasswordModule)
    },
    {
        path: 'forgotcompany', canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard],
        loadChildren: () => import('./components/ForgotCompany/forgot-company.module').then(m => m.ForgotCompanyModule)
    },
    {
        path: 'setupgauth', canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard],
        loadChildren: () => import('./components/SetupGAuth/setupgauth.module').then(m => m.SetUpGauthModule)
    },
    {
        path: 'setupmicrosoftauth', canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard],
        loadChildren: () => import('./components/SetupMicrosoftAuthenticator/setup-microsoft-authenticator.module').then(m => m.SetupMicrosoftAuthModule)
    },
    {
        path: 'verifydomain', canActivate: [canActivateCookieGuard], canActivateChild: [canActivateChildCookieGuard], canMatch: [canMatchCookieGuard],
        loadChildren: () => import('./components/VerifyDomain/verify-domain.module').then(m => m.VerifyDomainModule)
    },
    {
        path: 'logout', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard],
        loadChildren: () => import('./components/logout/logout.module').then(m => m.LogoutModule)
    },
    {
        path: Constants.userManagementPath, canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.ENABLEUSERSECTIONVIEW] },
        loadChildren: () => import('./components/UserManagement/UserManagement.module').then(m => m.UserManagementModule)
    },
    {
        path: 'application-management', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.ENABLEAPPLICATIONSECTIONVIEW] },
        loadChildren: () => import('./components/ApplicationManagement/application-management.module').then(m => m.ApplicationManagementModule)
    },
    {
        path: 'connection-management', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.ENABLEDIRECTORYSECTIONVIEW] },
        loadChildren: () => import('./components/ConnectionManagement/connection-management.module').then(m => m.ConnectionManagementModule)
    },
    {
        path: 'policy-management', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.ENABLEPOLICYSECTIONVIEW] },
        loadChildren: () => import('./components/PolicyManagement/policy-management.module').then(m => m.PolicyManagementModule)
    },

    {
        path: 'tenant-management', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.CREATECOMPANY, Permissions.GETCOMPANY] },
        loadChildren: () => import('./components/CompanyManagement/company-management.module').then(m => m.CompanyManagementModule)
    },
    {
        path: 'account-management', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.ENABLEACCOUNTSECTIONVIEW] },
        loadChildren: () => import('./components/AccountManagement/account-management.module').then(m => m.AccountManagementModule)
    },
    {
        path: 'error', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard],
        loadChildren: () => import('./components/ErrorManagement/error-management.module').then(m => m.ErrorManagementModule)
    },
    {
        path: 'profile-management', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard],
        loadChildren: () => import('./components/ProfileManagement/profile-management.module').then(m => m.ProfileManagementModule)
    },
    {
        path: 'domain', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.IDENTITYDOMAIN] },

        loadChildren: () => import('./components/domain-management/domain-management.module').then(m => m.DomainManagementModule)
    },

    {
        path: 'msp-management', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], data: { permission: [Permissions.VIEWMSPTENANTPORTAL] },
        loadChildren: () => import('./components/MSPManagement/msp-management.module').then(m => m.MspManagementModule)
    },
    
    { path: '', canActivate: [canActivateCookieGuard, canActivateGuard], canActivateChild: [canActivateChildGuard, canActivateChildCookieGuard], canMatch: [canMatchGuard, canMatchCookieGuard], component: HomeComponent },

   // { path: '**', component: NotFoundComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: false })
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthService
    ]
})
export class AppRoutingModule {
    constructor(private _router: Router){
        this._router.events.subscribe({
            next: (event) => {
                if (event instanceof NavigationStart && new RegExp(/[a-zA-Z].+\/\.\./).test(event.url)) {
                    this._router.navigateByUrl('page-not-found', { skipLocationChange: true });
                }
            }});
    }
}