import { EventEmitter, OnInit, Output } from '@angular/core';
import { AppTranslationService } from './../../../services/app-translation.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    Renderer2,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'input-icon',
    templateUrl: './input-icon.ctrl.html',
    styleUrls: ['./input-icon.ctrl.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputIconControl),
            multi: true
        }
    ]
})
export class InputIconControl implements ControlValueAccessor, AfterViewInit, OnInit {
    @Output() popUpEvent = new EventEmitter<boolean>();
    @Input()
    public icon: string = '';

    @Input()
    public type: string = 'text';

    @Input()
    public placeholder: string = '';

    @Input()
    public autocomplete: string = 'on';

    @Input()
    public autoFocus: boolean = false;

    @Input()
    public showEyeIcon: boolean = false;

    @Input() maxlength?: number;

    private passwordEyeIconShownLabel : string;
    private passwordEyeIconHiddenLabel : string;
    public showDefaultConfirmPassword: boolean;
    public showDefaultPassword: boolean;
    private passwordShowHideStatusLabel : string;
    public disableConfirmPassword: boolean = false;
    
    public get value() {
        return this._value;
    }

    public set value(value: string) {
        this._value = value;
        this._propagateChange(this._value);
    }

    private _value: string = '';

    @ViewChild('input')
    private _input: ElementRef;

    constructor(private _renderer: Renderer2,
        private _el: ElementRef,
        private _liveAnnouncer: LiveAnnouncer,
        private translationService: AppTranslationService
        ) {

    }

    public ngOnInit(): void{
        this.passwordEyeIconShownLabel = this.translationService.getTranslation('lbl_passwordEyeIcon_shown')
        this.passwordEyeIconHiddenLabel = this.translationService.getTranslation('lbl_passwordEyeIcon_hidden')
        this.passwordShowHideStatusLabel = this.translationService.getTranslation('lbl_header_status')
    }


    public ngAfterViewInit() {
        if (this.autoFocus) {
            const element = this._renderer.selectRootElement(this._input.nativeElement);
        setTimeout(() => element.focus(), 100);
            // setTimeout(() => {
            //     this._renderer.invokeElementMethod(this._input.nativeElement, 'focus');
            // }, 100);
        }
    }

    
    /**
     * Handles whenever the element is clicked. Used for testing
     * 
     * 
     * @memberOf InputIconControl
     */
    @HostListener('click', ['$event'])
    public handleClick() {
        if(document.activeElement.className!='icon-show-password')
        {
            this._input.nativeElement.focus();
        }
    }

    public writeValue(obj: any): void {
        this.value = obj;
    }

    public registerOnChange(fn: any): void {
        this._propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public passwordToggle(passwordField: string): void {
        if ( passwordField === 'showDefaultPassword') {
            this.showDefaultPassword = !this.showDefaultPassword;
            this._liveAnnouncer.announce(this.showDefaultPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel, 'assertive');
        }
        if ( passwordField === 'showDefaultConfirmPassword' && !this.disableConfirmPassword) {
            this.showDefaultConfirmPassword = !this.showDefaultConfirmPassword;
            this._liveAnnouncer.announce(this.showDefaultConfirmPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel, 'assertive');
        }
    }

    public passwordToggleStatus(passwordField: string): void {
        if ( passwordField === 'showDefaultPassword') {
            this._liveAnnouncer.announce(this.passwordShowHideStatusLabel + ", " + (this.showDefaultPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel), 'polite');
        }
        if ( passwordField === 'showDefaultConfirmPassword' && !this.disableConfirmPassword) {  
            this._liveAnnouncer.announce(this.passwordShowHideStatusLabel + ", " + (this.showDefaultConfirmPassword === true ? this.passwordEyeIconShownLabel : this.passwordEyeIconHiddenLabel), 'polite');
        }
    }

    public setDisabledState(isDisabled: boolean) {
        this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
        this._renderer.setProperty(this._input.nativeElement, 'disabled', isDisabled);
        // disable other components here
    }

    private _propagateChange: Function = () => { };

    public focusTrigger()
    {
        this.popUpEvent.emit();
    }
}