import { Component, Input, OnInit } from '@angular/core';
import { InactivityService } from '../../services/user-session-services/inactivity.service';
import { AccountEndpoint } from 'src/app/services/account-services/account-endpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/constants';
import { BehaviorSubject } from 'rxjs-compat';
import { ModalDialogRef, ModalDialogService2 } from 'src/app/shared/controls/dialogs';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-session-warning',
  templateUrl: './session-warning.component.html',
  styleUrls: ['./session-warning.component.scss'],
})
export class SessionWarningComponent implements OnInit {
  @Input()
  public isAppInactive = false;
  private modalRef: ModalDialogRef<null>;
  private worker: Worker | undefined;
  showWarning: boolean = false;
  timeLeftInSeconds: number = Constants.DefaultSessionWarningTime;
  displayTime: string = '';
  fullname: string = '';
  alertmessage: string = '';
  disablebutton: boolean = false;
  stoptimer: boolean = false;
  private alertMessageSubject = new BehaviorSubject<string>('');

  constructor(
    private inactivityService: InactivityService,
    private accountEndpointService: AccountEndpoint,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public _modalDialogService: ModalDialogService2
  ) {}

  ngOnInit(): void {
    this.inactivityService.inactivityAlert.subscribe((x) => {
      this.updateDisplayTime();
      this.showWarning = true;
      this.fullname = this.authService.currentUser.FirstName;
      if (this.authService.currentUser.LastName !== undefined) {
        this.fullname = this.fullname + ' ' + this.authService.currentUser.LastName;
      }
      this.alertmessage = this.translationService
        .getTranslation('msg_session_timeout_alert')
        .replace('<fullname>', this.fullname);

      this.startWorkerCountdown();
    });
  }

  private startWorkerCountdown() {
    if (typeof Worker !== 'undefined') {
      // Initialize the worker if it doesn't exist
      if (!this.worker) {
        this.worker = new Worker(new URL('src/app/inactivity-timer.worker', import.meta.url));
      }
  
      // Reset the timer on the main thread
      this.timeLeftInSeconds = Constants.DefaultSessionWarningTime;
  
      // Listen for messages from the worker
      this.worker.onmessage = ({ data }) => {
        if (data === 'timer_tick') {
          console.log(this.timeLeftInSeconds);
          // Decrement the timer in the component
          this.timeLeftInSeconds--;
  
          if (this.timeLeftInSeconds > 0) {
            this.updateDisplayTime();
          } else {
            // Timer expired
            this.logout();
            if (this.worker) {
              this.worker.postMessage({ type: 'stop' }); // Stop the worker
            }
          }
        }
      };
  
      // Start the worker timer
      this.worker.postMessage({
        type: 'start',
        inactivityThreshold: Constants.DefaultSessionWarningTime * 1000,
        pollingInterval: 1000, // Poll every 1 second
      });
    } else {
      console.error('Web Workers are not supported in this environment.');
    }
  }

  extendSession() {
    this.stoptimer = true;

    if (this.worker) {
      this.worker.postMessage({ type: 'stop' });
    }

    this.accountEndpointService.ValidateUserSession().subscribe((x) => {
      if (x === true) {
        this._modalDialogService.closeModal();
        this.showWarning = false;
        this.displayTime = '';
        this.timeLeftInSeconds = Constants.DefaultSessionWarningTime;
        this.inactivityService.resetInactivityTimer();
        this.isAppInactive = false;
      }
    });
  }

  logout() {
    this.stoptimer = true;
    this.disablebutton = true;

    if (this.worker) {
      this.worker.postMessage({ type: 'stop' });
    }

    this.authService.redirectToLogoutUrl();
    ////this.authService.ssoLogout();
  }

  private updateDisplayTime() {
    const minutes = Math.floor(this.timeLeftInSeconds / 60);
    const seconds = this.timeLeftInSeconds % 60;
    if (minutes > 0) {
      this.displayTime = `${minutes} minutes ${seconds < 10 ? '0' : ''}${seconds} seconds`;
    } else {
      this.displayTime = `${seconds < 10 ? '0' : ''}${seconds} seconds`;
    }
  }
}
