import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UserPasskeyList } from "src/app/models/UserPasskeyList";
import { AppTranslationService } from "src/app/services/app-translation.service";
import { UserListService } from "src/app/services/user-list-service";
import { UserService } from "src/app/services/user-services/user.service";
import {
    ModalDialogRef,
    ModalDialogService2,
    SOTI_MODAL_DATA
} from "src/app/shared/controls/dialogs";
import { appMessenger } from "src/app/shared/controls/toaster-messages/AppMessenger";
import { AlertSeverityEnum } from "src/app/shared/enums/AlertSeverityEnum";
import { ErrorCode } from "src/app/shared/enums/ErrorCode";
import { ClientNotificationMessage, NotificationType } from "src/app/shared/utils/toast-utils/ClientNotificationMessage";

export class EditModalParam {
    public title: string;
    public okButtonText: string;
    public userId: string;

    public sotionetheme: boolean;

    userPasskey: UserPasskeyList

    constructor(sotiOneTheme: boolean, userPasskey: UserPasskeyList) {
        this.sotionetheme = sotiOneTheme;
        this.userPasskey = userPasskey;
    }
}
@Component({
    selector: "edit-passkey",
    styleUrls: ["./edit-passkey-modal.ctrl.scss"],
    templateUrl: "./edit-passkey-modal.ctrl.html",
})
export class EditPasskeyModal implements OnInit {
    @Input() public isChangedByAdmin: boolean;
    @Output() public refreshPassKeyList : EventEmitter<null> = new EventEmitter();
    @Output() public checkDuplicatePassKey : EventEmitter<string> = new EventEmitter();
    public showLoader: boolean = false;
    public titleName: string;
    public titleIcon: string;
    private userId: string;
    private IsEdit:boolean = false;
    private submitted: boolean;
    
    public helpId: string;
    public sotionetheme: boolean = false;
    public duplicatePasskeyName: boolean = false;
    public platform: string;
    public buttonText: string;
    public initialPlatform: string;
    public hasChanges: boolean = false;

    @ViewChild('passkeyName') search: ElementRef;
    constructor(
        private userService: UserService,
        private _modalRef: ModalDialogRef<EditPasskeyModal>,
        private translationService: AppTranslationService,
        private userlistservice: UserListService,
        private _modalDialogService: ModalDialogService2,
        @Inject(SOTI_MODAL_DATA) public data: EditModalParam
    ) {
        this.sotionetheme = this.data?.sotionetheme;
        if (this.data.userPasskey){
            this.platform = this.data.userPasskey.Platform;
            this.IsEdit = true;
        }
        else{
            this.platform = '';
        }
        this.initialPlatform = this.platform;
    }

    ngOnInit(): void {
        this.showLoader = false;
        const defaultTitle = 'lbl_add_passkey';

        if (this.IsEdit) {
            this.titleIcon = 'identity-icon-edit'
            this.titleName = this.translationService.getTranslation('lbl_rename_passkey');
            this.buttonText = this.translationService.getTranslation('btn_Update');
        } else {
            this.titleIcon = 'identity-icon-passkey'
            this.titleName = this.translationService.getTranslation(defaultTitle);
            this.buttonText = this.translationService.getTranslation('btn_add_passkey');
        }
        this.helpId = 'RenamePasskey';
    }

    public closeModal(): void {
        this._modalDialogService.closeModal();
    }

    public submit(isValid: boolean):void{
        if (!isValid){
            return;
        }
        
        if (this.IsEdit){
            this.updatePasskeyName();
        }
        else{
            this.AddPasskeys();
        }
    }

    public onInputChange(): void{
        this.hasChanges = true;
        this.changePasskeyNameCheck()
    }

    private changePasskeyNameCheck(): void {
        this.checkDuplicatePassKey.emit(this.platform)

        if (this.platform.toLowerCase() === this.initialPlatform.toLowerCase()) {
            this.hasChanges = false;
        } else {
            this.hasChanges = true;
        }
   }

    private async AddPasskeys(): Promise<void> {
        this.showLoader = true;
        let makeCredentialOptions;
        const data = await this.userService.getPasskeyConfigData().toPromise();
        makeCredentialOptions = JSON.parse(data);
        makeCredentialOptions.challenge = this.coerceToArrayBuffer(makeCredentialOptions.challenge, 'challenge');
        makeCredentialOptions.user.id = this.coerceToArrayBuffer(makeCredentialOptions.user.id, 'userid');
        makeCredentialOptions.attestation = "none";
        
        makeCredentialOptions.pubKeyCredParams.forEach(param => {
            param.type = "public-key";
            });
        makeCredentialOptions.excludeCredentials.forEach(param => {
            param.type = "public-key";
            param.id = this.coerceToArrayBuffer(param.id, 'string');
        });

        await navigator.credentials.create({ publicKey: makeCredentialOptions })
        .then((data) => {
            this.registerNewCredential(data);
        })
        .catch((error) => {
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('msg_passkey_user_cancelled'),
                this.translationService.getTranslation('msg_passkey_user_cancelled'),
                NotificationType.Warning,
                AlertSeverityEnum.Minor));
            this.showLoader = false;
        }).finally(()=>{
        });
    }

    private updatePasskeyName(): void {
        if (this.data.userPasskey.ReferenceId != undefined) {
            this.userService.updatePasskey(this.data.userPasskey.ReferenceId,this.platform).subscribe(response => {
                appMessenger.postMessage(new ClientNotificationMessage(
                    this.translationService.getTranslation('msg_passkey_successfully_updated'),
                    this.translationService.getTranslation('msg_passkey_successfully_updated'),
                    NotificationType.Success,
                    AlertSeverityEnum.Minor));
                this.userlistservice.groupListValueObserver.next(true);
                setTimeout(() => {
                    this._modalDialogService.closeModal();    
                }, 0);
            }, error => {
                switch (error.error.ErrorCode) {
                    case ErrorCode.PasskeyNameAlreadyExist: {
                      this.duplicatePasskeyName = true;
                      break;
                    }
                    default: { }
                  }
                let message = this.translationService.getTranslation('msg_passkey_failed_updated');
                appMessenger.postMessage(new ClientNotificationMessage(this.translationService.getTranslation('msg_passkey_failed_updated'),
                    message,
                    NotificationType.Error,
                    AlertSeverityEnum.Minor));
            });
        }
    }


    private registerNewCredential(passkeyResponseData){
        let attestationObject = new Uint8Array(passkeyResponseData.response.attestationObject);
        let clientDataJSON = new Uint8Array(passkeyResponseData.response.clientDataJSON);
        let rawId = new Uint8Array(passkeyResponseData.rawId);
        let rawIdArray = new Uint8Array(passkeyResponseData.rawId);
        let rawIdBase64Url = this.coerceToBase64Url(rawIdArray);
       
        let transports = passkeyResponseData.response.getTransports ? passkeyResponseData.response.getTransports() : [];
        let response = {
            id: passkeyResponseData.id,
            rawId: rawIdBase64Url,
            type: passkeyResponseData.type,
            extensions: passkeyResponseData.getClientExtensionResults(),
            platform: this.platform,
            transports: transports,
            response: {
                AttestationObject: this.coerceToBase64Url(attestationObject),
                clientDataJSON: this.coerceToBase64Url(clientDataJSON),
            }
        };

        this.registerCredentialWithServer(response);
    }

    private async registerCredentialWithServer(formData) : Promise<any> {
        const newData = await this.userService.registerPasskeys(formData).toPromise();
        this._modalDialogService.closeModal();
        let data = newData;
        if(data != null){
            this.showLoader = false;
            this.refreshPassKeyList.emit();
            appMessenger.postMessage(new ClientNotificationMessage(
                this.translationService.getTranslation('mgs_register_passkey_successfully'),
                this.translationService.getTranslation('mgs_register_passkey_successfully'),
                NotificationType.Success,
                AlertSeverityEnum.Minor));
        }
        else{
            let message = this.translationService.getTranslation('msg_Somethingwentworng_passkey');
            appMessenger.postMessage(new ClientNotificationMessage(this.translationService.getTranslation('msg_Error'),
                message,
                NotificationType.Error,
                AlertSeverityEnum.Minor));
        }
        return data;
    }

    private coerceToArrayBuffer(thing: any, name: string): ArrayBuffer {
        if (typeof thing === 'string') {
            thing = this.base64UrlToUint8Array(thing);
            // // if (thing === name) {
            // //     // If the input string is the same as the name, return an empty ArrayBuffer
            // //     return new ArrayBuffer(0);
            // //   } else {
            // //     thing = this.base64UrlToUint8Array(thing);
            // //   }
        }
    
        if (Array.isArray(thing)) {
          thing = Uint8Array.from(thing);
        }

        if (thing instanceof Uint8Array) {
            thing = thing.buffer;
        }
    
        if (!(thing instanceof ArrayBuffer)) {
          throw new TypeError(`Could not coerce '${name}' to ArrayBuffer`);
        }
    
        return thing;
    }

    private coerceToBase64Url(thing : any): ArrayBuffer {
        // Array or ArrayBuffer to Uint8Array
        if (Array.isArray(thing)) {
            thing = Uint8Array.from(thing);
        }
    
        if (thing instanceof ArrayBuffer) {
            thing = new Uint8Array(thing);
        }
    
        // Uint8Array to base64
        if (thing instanceof Uint8Array) {
            let str = "";
            let len = thing.byteLength;
    
            for (let i = 0; i < len; i++) {
                str += String.fromCharCode(thing[i]);
            }
            thing = window.btoa(str);
        }
    
        if (typeof thing !== "string") {
            throw new Error("could not coerce to string");
        }
    
        // base64 to base64url
        // NOTE: "=" at the end of challenge is optional, strip it off here
        thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
    
        return thing;
    }

    private base64UrlToUint8Array(base64url: string): Uint8Array {
        const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }
}
