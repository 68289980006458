import { PasswordPolices } from './../../models/passwordPolicies';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationListFilterParams } from 'src/app/models/ApplicationListFilterParams';
import { AssociatedMspTenants } from 'src/app/models/AssociatedMspTenants';
import { MspChangeStatusRequest } from 'src/app/models/MspChangeStatusRequest';
import { MspParentTenantAssociation } from 'src/app/models/MspParentTenantAssociation';
import { TenantAuthority } from 'src/app/models/TenantAuthority.Model';
import { TenantAuthorityDetailsUI } from 'src/app/models/TenantAuthorityDetailsUI.model';
import { ApplicationList } from 'src/app/models/applicationList.modal';
import { Roles } from 'src/app/models/applicationRoles.model';
import { ApplicationUser } from 'src/app/models/applicationUser.model';
import { CompanyListUI } from 'src/app/models/company-list-ui.model';
import { DuoAuthenticationConfiguration } from 'src/app/models/duoAuthenticationConfiguration.model';
import { DuoUsernameFormat } from 'src/app/models/duoUsernameFormat.model';
import { EmailAuthenticationConfiguration } from 'src/app/models/emailAuthenticationConfiguration';
import { FilteringParams } from 'src/app/models/filteringparams';
import { GoogleAuthenticationConfiguration } from 'src/app/models/googleAuthenticationConfiguration.model';
import { ListResponse } from 'src/app/models/list-response.model';
import { MspAssociationRequest } from 'src/app/models/mspAssociationRequest.model';
import { MspChildRequest } from 'src/app/models/mspChildRequest.model';
import { MspRequestHistory } from 'src/app/models/mspRequestHistory.model';
import { MspTenantLinkingFilterParams } from 'src/app/models/mspTenantLinkingFilterParams';
import { TenantBackgroundImages } from 'src/app/models/tenantBackgroundImages.model';
import { TenantFeature } from 'src/app/models/tenantFeature.model';
import { TenantPreferenceseModel } from 'src/app/models/tenantPreferences.model';
import { UserApplication } from 'src/app/models/userApplication.model';
import { Company } from '../../models/company.model';
import { Country } from '../../models/country.model';
import { IndustryType } from '../../models/industry-type.model';
import { CompanyFeatureEnum } from '../../shared/enums/CompanyFeatureEnum';
import { CompanyEndpoint } from './company-endpoint.service';
import { AuthorityListFilterParams } from 'src/app/models/AuthorityListFilterParams';
import { AssignedApplicationModel } from 'src/app/models/assignedApplication.model';
import { MspLinkingModel } from '../../models/msplinking.model';
import { TenantLogo} from 'src/app/models/tenantLogoImages.model';
import { RestrictedUserTenantPreferences } from 'src/app/models/RestrictedUserTenantPreferences';
import { MspTenantInfo } from 'src/app/models/MspTenantInfo';

@Injectable()
export class CompanyService {
    public selectedCompanies: BehaviorSubject<CompanyListUI[]> = new BehaviorSubject([]);
    constructor(
        private companyEndpoint: CompanyEndpoint
    ) { }


    getCompanybyId(referenceId?: string) {
        return this.companyEndpoint.getCompanyByIdEndPoint<Company>(referenceId);
    }

    getCompanyGroups(referenceId: string) {
        return this.companyEndpoint.getCompanyGroupsEndpoint(referenceId);
    }

    public createCompany(company: Company): Observable<Company> {
        return this.companyEndpoint.createCompanyEndpointService(company);
    }

    public updateCompany(company: Company): Observable<void> {
        return this.companyEndpoint.updateCompany(company);
    }

    public getCountries(): Observable<Country[]> {
        return this.companyEndpoint.getCountries();
    }

    public getTenantSession(tenantRefId: string): Observable<number>{
        return this.companyEndpoint.getTenantSession(tenantRefId);
    }
    
    public updateTenantSession(tenantRefId: string, session: number): Observable<any>{
        return this.companyEndpoint.updateTenantSession(tenantRefId,session);
    }

    public getIndustryType(): Observable<IndustryType[]> {
        return this.companyEndpoint.getIndustryType();
    }

    public verifyEmailAddress(emailAddress: string): Observable<boolean> {
        return this.companyEndpoint.verifyEmailAddress(emailAddress);
    }
    public verifyTenantId(tenantId: string): Observable<boolean> {
        return this.companyEndpoint.verifyTenantId(tenantId);
    }
    public verifyduplicateTenantId(tenantId: string): Observable<void> {
        return this.companyEndpoint.verifyDuplicateTenantId(tenantId);
    }
    public getCompanies(filteringParams?: FilteringParams): Observable<ListResponse<CompanyListUI>> {
        return this.companyEndpoint.getCompanies(filteringParams);
    }

    public getSelectedCompany(): Observable<CompanyListUI[]> {
        return this.selectedCompanies;
    }

    public getEmailAuthenticationConfiguration(companyReferenceId: string): Observable<EmailAuthenticationConfiguration> {
        return this.companyEndpoint.getEmailAuthenticationConfiguration(companyReferenceId);
    }

    public saveEmailAuthenticationConfiguration(companyReferenceId: string, configuration: EmailAuthenticationConfiguration)
        : Observable<void> {
        return this.companyEndpoint.saveEmailAuthenticationConfiguration(companyReferenceId, configuration);
    }

    public updateEmailAuthenticationConfiguration(companyReferenceId: string, configuration: EmailAuthenticationConfiguration)
        : Observable<void> {
        return this.companyEndpoint.updateEmailAuthenticationConfiguration(companyReferenceId, configuration);
    }

    public getGoogleAuthenticationConfiguration(companyReferenceId: string): Observable<GoogleAuthenticationConfiguration> {
        return this.companyEndpoint.getGoogleAuthenticationConfiguration(companyReferenceId);
    }

    public validateGoogleAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        return this.companyEndpoint.validateGoogleAuthenticationConfiguration(companyReferenceId);
    }

    public validateEmailAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        return this.companyEndpoint.validateEmailAuthenticationConfiguration(companyReferenceId);
    }

    public validateDuoAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        return this.companyEndpoint.validateDuoAuthenticationConfiguration(companyReferenceId);
    }

    public updateGoogleAuthenticationConfiguration(companyReferenceId: string, configuration: GoogleAuthenticationConfiguration)
        : Observable<void> {
        return this.companyEndpoint.updateGoogleAuthenticationConfiguration(companyReferenceId, configuration);
    }

    public getMicrosoftAuthenticationConfiguration(companyReferenceId: string): Observable<GoogleAuthenticationConfiguration> {
        return this.companyEndpoint.getMicrosoftAuthenticationConfiguration(companyReferenceId);
    }

    public validateMicrosoftAuthenticationConfiguration(companyReferenceId: string): Observable<boolean> {
        return this.companyEndpoint.validateMicrosoftAuthenticationConfiguration(companyReferenceId);
    }

    public updateMicrosoftAuthenticationConfiguration(companyReferenceId: string, configuration: GoogleAuthenticationConfiguration)
        : Observable<void> {
        return this.companyEndpoint.updateMicrosoftAuthenticationConfiguration(companyReferenceId, configuration);
    }

    public deleteCompanies(companyReferenceIds: string[]): Observable<void> {
        return this.companyEndpoint.deleteCompanies(companyReferenceIds);
    }

    public getCompanyApplications(companyReferenceId: string): Observable<ApplicationList[]> {
        return this.companyEndpoint.getCompanyApplications(companyReferenceId);
    }

    public getCompanyFeatures(companyReferenceId: string): Observable<TenantFeature[]> {
        return this.companyEndpoint.getCompanyFeatures(companyReferenceId);
    }

    public assignCompanyFeatures(companyReferenceId: string, features: CompanyFeatureEnum[]): Observable<void> {
        return this.companyEndpoint.assignCompanyFeatures(companyReferenceId, features);
    }

    public getDuoAuthenticationConfiguration(companyReferenceId: string): Observable<DuoAuthenticationConfiguration> {
        return this.companyEndpoint.getDuoAuthenticationConfiguration(companyReferenceId);
    }

    public postDuoAuthenticationConfiguration(companyReferenceId: string, configuration: DuoAuthenticationConfiguration) {
        return this.companyEndpoint.postDuoAuthenticationConfiguration(companyReferenceId, configuration);
    }

    public putDuoAuthenticationConfiguration(companyReferenceId: string, configuration: DuoAuthenticationConfiguration) {
        return this.companyEndpoint.putDuoAuthenticationConfiguration(companyReferenceId, configuration);
    }

    public getDuoUsernameFormats(): Observable<DuoUsernameFormat[]> {
        return this.companyEndpoint.getDuoUsernameFormats();
    }

    public assignApplication(companyReferenceId: string, applicationReferenceIds: string[]): Observable<void> {
        return this.companyEndpoint.assignApplication(companyReferenceId, applicationReferenceIds);
    }

    public deactivateCompanies(companyRererenceIds: string[]): Observable<void> {
        return this.companyEndpoint.deactivateCompanies(companyRererenceIds);
    }

    public activateCompanies(companyRererenceIds: string[]): Observable<void> {
        return this.companyEndpoint.activateCompanies(companyRererenceIds);
    }

    public getTenantPasswordPolicy(token: string): Observable<PasswordPolices> {
        return this.companyEndpoint.getTenantPasswordPolicy(token);
    }

    public getGeneratedPassword(roleReferenceIds: string[], userId?: string): Observable<string> {
        return this.companyEndpoint.getGeneratedPassword({RoleReferenceIds:roleReferenceIds, UserId:userId});
    }

    public postMspAssociationRequest(mspAssociationRequest: MspAssociationRequest): Observable<void> {
        return this.companyEndpoint.postMspAssociationRequest(mspAssociationRequest);
    }

    public postMspAssignApplication(parentTenantReferenceId: string, applicationReferenceIds: string[]): Observable<void> {
        return this.companyEndpoint.postMspAssignApplication(parentTenantReferenceId, applicationReferenceIds);
    }

    public getMspTenantAssociation(): Observable<MspParentTenantAssociation> {
        return this.companyEndpoint.getMspTenantAssociation();
    }

    public getAssociatedMspTenants(isMspTenantDropDown?: boolean): Observable<AssociatedMspTenants[]> {
        return this.companyEndpoint.getAssociatedMspTenants(isMspTenantDropDown);
    }

    public patchMspChildTenantAliasName(childTenantReferenceId: string, childTenantAliasName:string): Observable<void> {
        return this.companyEndpoint.patchMspChildTenantAliasName(childTenantReferenceId, childTenantAliasName);
    }

    public getMspChildRequest(filteringParams: MspTenantLinkingFilterParams): Observable<ListResponse<MspChildRequest>> {
        return this.companyEndpoint.GetMspChildRequestEndpoint(filteringParams);
    }

    public getApplicationAssignedByMspChild(childTenantReferenceId: string, filter: ApplicationListFilterParams): Observable<ListResponse<ApplicationList>> {
        return this.companyEndpoint.getApplicationAssignedByMspChild(childTenantReferenceId, filter);
    }

    public patchMspRequestAction(childTenantReferenceId: string, mspRequestReferenceId: string, mspChangeStatusRequest: MspChangeStatusRequest): Observable<void> {
        return this.companyEndpoint.patchMspRequestAction(childTenantReferenceId, mspRequestReferenceId, mspChangeStatusRequest);
    }

    public patchMspRequestActionFromChild(parentTenantReferenceId: string, mspRequestReferenceId: string, mspChangeStatusRequest: MspChangeStatusRequest): Observable<void> {
        return this.companyEndpoint.patchMspRequestActionFromChild(parentTenantReferenceId, mspRequestReferenceId, mspChangeStatusRequest);
    }
    public getMspChildRequestHistory(childReferenceId: string): Observable<MspRequestHistory[]> {
        return this.companyEndpoint.getMspChildRequestHistory(childReferenceId);
    }

    public getApplications(childMspTenantReferenceId: string) {
        return this.companyEndpoint.getApplicationsEndpoint(childMspTenantReferenceId);
    }

    public getLongLoadingApplications(applicationType: number ,applicationreferenceIds: string[], childMspTenantReferenceId: string)
    {
        return this.companyEndpoint.getLongLoadingApplications(applicationType, applicationreferenceIds, childMspTenantReferenceId);
    }

    public postAssignDefaultMspApplications(mspTenantInfo: MspTenantInfo): void {
        this.companyEndpoint.postAssignDefaultMspApplications(mspTenantInfo).subscribe(() => { });
    }
    

    public getMspApplicationUsers(childTenantReferenceId: string, applicationReferenceId: string): Observable<ApplicationUser[]> {
        return this.companyEndpoint.getMspApplicationUsers(childTenantReferenceId, applicationReferenceId);
    }

    public deleteMspApplicationUser(childTenantReferenceId: string, applicationReferenceId: string, userReferenceId: string): Observable<void> {
        return this.companyEndpoint.deleteMspApplicationUser(childTenantReferenceId, applicationReferenceId, userReferenceId);
    }

    public patchDelink(parentTenantReferenceId: string): Observable<void> {
        return this.companyEndpoint.patchDelink(parentTenantReferenceId);
    }

    public assignApplicationToMspTenantUser(childTenantReferenceId: string, applicationReferenceId: string, userReferenceId: string, userApplication: UserApplication): Observable<void> {
        return this.companyEndpoint.assignApplicationToMspTenantUser(childTenantReferenceId, applicationReferenceId, userReferenceId, userApplication);
    }

    public getApplicationRoles(childTenantReferenceId: string, applicationReferenceId: string): Observable<Roles[]> {
        return this.companyEndpoint.getRolesListByApplicationIdEndPoint(childTenantReferenceId, applicationReferenceId);
    }

    public getTenantPreferences(): Observable<TenantPreferenceseModel[]> {
        return this.companyEndpoint.getTenantPreferences();
    }

    public getTenantBackgroundImages(): Observable<TenantBackgroundImages[]> {
        return this.companyEndpoint.getTenantBackgroundImages();
    }

    public getRestrictedUserTenantPreferences(): Observable<RestrictedUserTenantPreferences> {
        return this.companyEndpoint.getRestrictedUserTenantPreferences();
    }

    public getTokenUserTenantPreferences(token: string): Observable<RestrictedUserTenantPreferences> {
        return this.companyEndpoint.getTokenUserTenantPreferences(token);
    }

    public getTenantLogos(): Observable<TenantLogo[]> {
        return this.companyEndpoint.getTenantLogos();
    }
    public postTenantPreferences(preference: TenantPreferenceseModel[]) {
        return this.companyEndpoint.postTenantPreferences(preference);
    }

    public postTenantAuthorityConfiguration(tenantAuthorityDetailsUI: TenantAuthorityDetailsUI): Observable<void> {
        return this.companyEndpoint.postTenantAuthorityConfiguration(tenantAuthorityDetailsUI);
    }

    public postCustomBackgroundImage(file: File): Observable<void> {
        return this.companyEndpoint.postCustomBackgroundImage(file);

    }
    public postCustomLogoImage(tenantLogo: TenantLogo): Observable<void> {
        return this.companyEndpoint.postCustomLogoImage(tenantLogo);

    }

    public deleteCustomBackgroundImage(referenceId: string): Observable<void> {
        return this.companyEndpoint.deleteCustomBackgroundImage(referenceId);
    }
    
    public deleteCustomLogoImage(referenceId: string): Observable<void> {
        return this.companyEndpoint.deleteCustomLogoImage(referenceId);
    }
    public getTenantAuthorities(filteringParams?: AuthorityListFilterParams): Observable<TenantAuthority[]> {
        return this.companyEndpoint.getTenantAuthorities(filteringParams);
    }

    public deleteTenantAuthority(referenceId: string): Observable<void> {
        return this.companyEndpoint.deleteTenantAuthority(referenceId);
    }

    public getTenantAuthoritydata(referenceId: string): Observable<TenantAuthorityDetailsUI> {
        return this.companyEndpoint.getTenantAuthoritydata(referenceId);
    }

    public updateTenantAuthorityConfiguration(tenantAuthorityDetailsUI: TenantAuthorityDetailsUI, referenceId: string): Observable<void> {
        return this.companyEndpoint.updateTenantAuthorityConfiguration(tenantAuthorityDetailsUI, referenceId);
    }

    public verifyDuplicateAuthorityName(authorityName: string): Observable<boolean> {
        return this.companyEndpoint.verifyDuplicateAuthorityName(authorityName);
    }


    public getMspUserApplicationRoles(tenantReferenceId: string, applicationReferenceId: string, userReferenceId: string): Observable<Roles[]> {
        return this.companyEndpoint.getMspUserApplicationRoles(tenantReferenceId, applicationReferenceId, userReferenceId);
    }

    public getAssignedApplications(tenantReferenceId: string, userReferenceId: string): Observable<AssignedApplicationModel[]> {
        return this.companyEndpoint.getAssignedApplications(tenantReferenceId, userReferenceId);
    }
    
    public searchMspApplication(tenantReferenceId: string, searchText: string): Observable<ApplicationList[]> {
        return this.companyEndpoint.searchMspApplication(tenantReferenceId, searchText);
    }

    public postAssignAllMspApplications(childTenantReferenceId: string, userReferenceId: string): Observable<void> {
        return this.companyEndpoint.postAssignAllMspApplications(childTenantReferenceId, userReferenceId);
    }
    
    public postMspLinkingRequest(mspLinkingModel: MspLinkingModel): Observable<void> {
        return this.companyEndpoint.postMspChildLinkingRequest(mspLinkingModel);
    }
}
