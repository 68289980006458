import { Permissions } from 'src/app/shared/enums/Permissions';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";


import { ConnectionPositionPair, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { UserTypeEnum } from '../../enums/UserTypeEnum';
import { User } from 'src/app/models/user.model';
import { ViewLocationEnum } from '../../enums/ViewLocationEnum';
import { DisposalBag } from '../../utils/DisposalBag';
import { AuthService } from 'src/app/services/auth.service';
import { HeaderService } from '../header.service';
import { BrowserUtilsService } from 'src/app/services/browserUtil/browserUtils.service';
import { SotiThemeService } from 'src/app/services/soti-theme/soti-theme.service';
import { ThemeType } from '../../enums/ThemeType';
import { Utilities } from '../../../services/utilities';
import { CompanyService } from 'src/app/services/company-services/company.service';
import { TenantLogo } from 'src/app/models/tenantLogoImages.model';
import { UserPreferenceType } from '../../enums/UserPreferenceType';
import { TenantPreferenceseModel } from 'src/app/models/tenantPreferences.model';
import { UserPreferenceService } from 'src/app/services/user-preference-service/user-preference-service';

@Component({
    selector: 'so-header',
    templateUrl: './so-header.view.html',
    styleUrls: ['./so-header.view.scss']
})

export class SoHeaderView implements OnInit, OnDestroy  {
    public userType: UserTypeEnum;
    public showMenu: boolean = false;
    public showPopUp: boolean = false;
    public userName: string = '';
    public userInitials: string = '';
    public currentPage: string;
    public newViews: string;
    public showCurrentLocation: boolean = false;
    public showPopUpAfterBack: boolean = false;
    public isMobileView: boolean;
    public selectedDashboardLogoReferenceId: string;
    public tenantDashboardLogoImages: TenantLogo[];
    public selectedTenantDashboardLogoImages: TenantLogo;
    public selectedTenantDashboardLogoImagesUrl: string;
    public overlayPositions: ConnectionPositionPair[] = [
        {
            offsetX: undefined,
            offsetY: undefined,
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        }
    ];
    public navigationMenuPermission: Permissions[] ;
    public sotiOneLogo: string = "";
    @Output()
    public changedValue: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    public headerItemTabindex: number = 0;
    
    public isLogoLoaded: boolean = false;

    @ViewChild(CdkConnectedOverlay)
    private _connectedOverlay: CdkConnectedOverlay;
    private _disposalBag: DisposalBag = new DisposalBag();
    private _headerLabelPrefix: string = 'lbl_header_';
    public getImagePath: string;
    constructor(private authService: AuthService,
        private _headerService: HeaderService,
        private _browserUtilService: BrowserUtilsService,
        private _sotiThemeService: SotiThemeService,
        private userPreferenceService: UserPreferenceService

    ) {

        this._disposalBag.nextSub = this._headerService.currentLocation.subscribe(val => {
            if (val === ViewLocationEnum.Dashboard) {
                this.currentPage = '';
                this.showCurrentLocation = false;
            }
            else {
                this.currentPage = `${this._headerLabelPrefix}${val}`;
                this.showCurrentLocation = true;
            }
        });
        this.isMobileView = this._browserUtilService.isMobileOrTablet();
        this._sotiThemeService._isSotiTheme = true;
    }

    public ngOnInit(): void {
        this.getImagePath = Utilities.getAssestPath();
        this.getNavigationPermission();
        if (this.authService.currentUser !== undefined || this.authService.currentUser !== null) {
            let currentUser: User = this.authService.currentUser;
            currentUser.LastName = currentUser.LastName === undefined ? '' : currentUser.LastName;
            this.userInitials = currentUser.FirstName.charAt(0).toLocaleUpperCase() + currentUser.LastName.charAt(0).toLocaleUpperCase()
            if (currentUser.FirstName !== undefined && currentUser.FirstName.length > 0) {
                this.userName = currentUser.FirstName + ' ' + currentUser.LastName.charAt(0).toLocaleUpperCase();
            }
            this.userType = currentUser.UserType;   
            if (this._headerService._isPopOpne && this.isMobileView) {
                this.showPopUp = true;
            }         
        }

        this._disposalBag.nextSub = this.userPreferenceService.logoImage$.subscribe((logoUrl) => {
            this._disposalBag.nextSub = this.userPreferenceService.isLogoDefault$.subscribe((isLogoDefault) => {
                this.sotiOneLogo = logoUrl;
                if(isLogoDefault === "true")
                {
                    this.defaultLogo(this.sotiOneLogo)
                }
            });
        });
    }

    public onToggleMenu($event?: MouseEvent | TouchEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
   
        this.togglePopUp();
    }
   
    public togglePopUp(): void {
        if (this.showPopUp) {
            this.showPopUp = false;
            this._headerService._isPopOpne = false;
            this._disposalBag.dispose();
        } else {
            this.showPopUp = true;
            this._headerService._isPopOpne = true;
            this._disposalBag.nextSub = this._connectedOverlay.backdropClick.subscribe(() => {
                this.togglePopUp();
            });
        }
    }
   
    public onToggleMenuClose(): void {
        this.togglePopUp();
    }
    public OnValueChange(keyword: string): void {
        this.changedValue.emit(keyword);
    }
    public ngOnDestroy(): void {
        this.userPreferenceService.cleanUp();
        this._disposalBag.dispose();
    }

    public onImageLoad() {
        this.isLogoLoaded = true;
    }

    public onImageError(): void {
        this.isLogoLoaded = true;
        this.userPreferenceService.updateData(true);
        this._disposalBag.nextSub = this.userPreferenceService.logoImage$.subscribe((logoUrl) => {
            this._disposalBag.nextSub = this.userPreferenceService.isLogoDefault$.subscribe((isLogoDefault) => {
                this.sotiOneLogo = logoUrl;
                if(isLogoDefault === "true")
                {
                    this.defaultLogo(this.sotiOneLogo)
                }
            });
        });
      }

    private defaultLogo(logoImg: string): void {
        this._disposalBag.nextSub = this._sotiThemeService.selectedTheme.subscribe((val) => {
            if (val === ThemeType.DARK) {
                this.sotiOneLogo = Utilities.getLogoSotiOneDarkMode();
            }
            else {
                this.sotiOneLogo = logoImg;
            }
        });
    }

    private getNavigationPermission(): void {
        this.navigationMenuPermission = [Permissions.ENABLEUSERSECTIONVIEW, 
                                         Permissions.ENABLEAPPLICATIONSECTIONVIEW, 
                                         Permissions.ENABLEDIRECTORYSECTIONVIEW,
                                         Permissions.ENABLEACCOUNTSECTIONVIEW,
                                         Permissions.CREATECOMPANY, 
                                         Permissions.GETCOMPANY,
                                         Permissions.MANAGECOMPANY,
                                         Permissions.ENABLEPOLICYSECTIONVIEW,
                                         Permissions.VIEWPOLICY,
                                        Permissions.VIEWMSPTENANTPORTAL];
    }
}
