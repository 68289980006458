
import { AfterViewInit, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd, NavigationStart,
    Router
} from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AccountService } from '../services/account-services/account.service';
import { NotificationService } from '../services/notification.service';

import { PlatformLocation } from '@angular/common';
import { UserLogin } from '../models/user-login.model';
import { AppTitleService } from '../services/app-title.service';
import { AppTranslationService } from '../services/app-translation.service';
import { AuthService } from '../services/auth.service';
import { BrowserUtilsService } from '../services/browserUtil/browserUtils.service';
import { CompanyService } from '../services/company-services/company.service';
import { ConfigurationService } from '../services/configuration.service';
import { DataInitService } from '../services/data-init-services/data-init.service';
import { DBkeys } from '../services/db-keys';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { UserService } from '../services/user-services/user.service';
import { BrowserUtils } from '../shared/utils/BrowserUtils';
import { logger } from '../shared/utils/Logger';

import { FedratedLogoutService } from '../services/logout-services/fedrated-logout.service';
import { BodyBinding } from '../shared/decorators/body-binding';
import { SotiThemeService } from '../services/soti-theme/soti-theme.service';
import { LightTheme } from '../services/soti-theme/theme';
import { Constants } from '../constants';
import { InactivityService } from '../services/user-session-services/inactivity.service';
import { filter, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
let alertify: any = require('../assets/scripts/alertify.js');


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        '../shared/styles/base/base.scss',
        './app.component.scss',
        '../shared/styles/fonts/idp-fonts/style.css',
        '../shared/styles/fonts/SourceSansPro/source-sans-pro.css',
        '../shared/styles/fonts/GothamRounded/gotham_rounded.css'
    ],

    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {


    public headerHide: boolean = true;
    public isHeaderRequired: boolean = false;
    public urlList: string[];
    public underMaintenance: boolean = false;
    public isAppInactive: boolean = false;
    public isAppLoaded: boolean = false;

    @HostBinding('class.is-desktop')

    @BodyBinding('class.is-desktop')
    private _isDesktop: boolean = false;

    @BodyBinding('class.is-tablet')
    private _istablet: boolean = false;

    @BodyBinding('class.is-windows')
    private _isWindows: boolean = false;

    @BodyBinding('class.modern')
    private _isModern: boolean = false;

    @BodyBinding('class.ie')
    private _isIE: boolean = false;

    @BodyBinding('class.edge')
    private _isEdge: boolean = false;

    @BodyBinding('class.firefox')
    private _isFirefox: boolean = false;

    @BodyBinding('class.chrome')
    private _isChrome: boolean = false;

    userLogin = new UserLogin();
    isLoading = false;

    isResetPassPagevisible = false;
    isUserLoggedIn: boolean;
    isCookieEnable: boolean = true;
    relayState: string = '';
    loggedInUserName: string = '';
    shouldShowLoginModal: boolean;
    removePrebootScreen: boolean;
    newNotificationCount = 0;
    appTitle = '';
    token: string;
    loading;

    stickyToasties: number[] = [];

    dataLoadingConsecutiveFailurs = 0;
    notificationsLoadingSubscription: any;

    //@ViewChildren('loginModal,loginControl') modalLoginControls: QueryList<any>;

    loginModal: ModalDirective;
    idpLogoutUrl: string;
    samlRequest: string;


    get notificationsTitle() {
        let gT = (key: string) => this.translationService.getTranslation(key);

        if (this.newNotificationCount)
            return `${gT('Notifications')} (${this.newNotificationCount
                } ${gT('New')})`;
        else return gT('Notifications');
    }

    constructor(
        storageManager: LocalStoreManager,
        private accountService: AccountService,
        private appTitleService: AppTitleService,
        public authService: AuthService,
        private translationService: AppTranslationService,
        public configurations: ConfigurationService,
        private _location: PlatformLocation,
        private companyService: CompanyService,
        private _browserUtil: BrowserUtilsService,
        private _dataInitService: DataInitService,
        private router: Router,
        private fedratedLogoutService: FedratedLogoutService,
        private userService: UserService,
        private notificationService: NotificationService,
        private themeService: SotiThemeService,
        private inactivityService: InactivityService
    ) {

        translationService.addLanguages(['en-US', 'jp-JP']);
        translationService.setDefaultLanguage('en-US');
        this.appTitleService.appName = this.appTitle;
        this.isCookieEnable = window.navigator.cookieEnabled;
        this.loading = true;
    }

    public ngOnInit(): void {
        this._isDesktop = !this._browserUtil.isMobileOrTablet();
        this._istablet = this._browserUtil.isMobileOrTablet();
        this._isWindows = this._browserUtil.isWindows();
        this._isModern = !this._browserUtil.isIE11();
        this._isEdge = this._browserUtil.isEdge();
        this._isIE = this._browserUtil.isIE11();
        this._isFirefox = this._browserUtil.isFirefox();
        this._isChrome = this._browserUtil.isChrome();

        this.token = BrowserUtils.getQueryStringParams(DBkeys.IsUserLoggedIn);

        if (this.token !== undefined && this.token !== null) {
            this.authService.setAccessToken(DBkeys.IsUserLoggedIn, this.token);
        }
        this.configurations.setApiUrl();
        this.configurations.apiUrlObserver.subscribe((result) => {
            if (result) {
                this.isAppLoaded = true;
                this.authService.subScribeToLoginPage();
                this.initiateConfiguration();
            }
        });

        let relayStateFromApi = BrowserUtils.getQueryStringParams(DBkeys.Relay_State);
        this.relayState = (relayStateFromApi === undefined || relayStateFromApi === null) ? "/" : relayStateFromApi;
        this.urlList = [
            // '/resetpassword', 
            // '/error', 
            // '/verifyDomain',
            // '/forgotpassword'
        ];

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.headerHide = this.isUrlExists(event.url);
            }
        });
        this.authService.underMaintenance.subscribe((result: boolean) => {
            this.underMaintenance = result;
        });

        this.authService.isHeaderRequired.subscribe((result: boolean) => {
            this.isHeaderRequired = result;
        });

        this.authService._isUserFullyLoggedIn.subscribe((result: boolean) => {
            this.isUserLoggedIn = result;
        });
        this.themeService.setTheme(new LightTheme());
    }

    public ngAfterViewInit(): void {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.loading = true;
                }
                else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel
                ) {
                    this.loading = false;
                }
            });
    }
    private initiateConfiguration(): void {
        this._dataInitService.getAdminConsoleConfiguration();
        if (this.token !== undefined && this.token !== null) {
            this.authService.setAccessToken(DBkeys.IsUserLoggedIn, this.token);
            this._dataInitService.getEnumsData();
            this.userService.getUserDetails().subscribe(userModel => {
                this.fedratedLogoutService.createConnection(this.token);
                this.notificationService.initializeNotifications(userModel.ReferenceId);
                this.loggedInUserName = userModel.FirstName;
                this._location.replaceState('', '', this._location.pathname);
                this.showEula(userModel.EulaAccepted);
                this.isUserLoggedIn = true;
                this.companyService.getCompanybyId(userModel.TenantReferenceId).subscribe(companyModel => {
                    companyModel.AdminEmails = null;
                    this.authService.setCurrentCompany(companyModel);

                    this.accountService.getSystemParams().subscribe(systemParamModel => {
                        this.authService.setSystemParameters(systemParamModel);
                        this.authService.setCurrentUser(userModel);
                    },
                        error => {
                            logger.error(error);
                            this.authService.showLoadingIconOnLogin = false;
                        }

                    );


                },
                    error => {
                        logger.error(error);
                        this.authService.showLoadingIconOnLogin = false;
                    }

                );


            });
        }
        else if (this.authService.currentUser != undefined && this.authService.currentUser != null) {
            if (this.authService.accessToken !== undefined && this.authService.accessToken !== null) {
                setTimeout(() => {
                    this.fedratedLogoutService.createConnection(this.authService.accessToken)
                }, this.configurations.ClientToSignalRLoadingTime);
            }
            let url = this.router.url ? this.router.url : "";
            if (url.split('?')[0] != `/${Constants.AppRedirectUrl}`) {
                this._dataInitService.getEnumsData();
                this.notificationService.initializeNotifications(this.authService.currentUser.ReferenceId);
            }
            this.authService._loginStatus.next(true);
            this.authService.setCurrentUser(this.authService.currentUser);
            this.showEula(this.authService.currentUser.EulaAccepted);
        }
        this.inactivityService.setSessionDuration(1);
        this.inactivityService.startInactivityCheck();
    }
    private isUrlExists(url: string): boolean {
        let urlExists = false;
        for (let i = 0; i <= this.urlList.length; i++) {
            let urlLists = url.indexOf(this.urlList[i]);
            if (urlLists > -1) {
                urlExists = true;
                break;
            }

        }
        return urlExists;
    }
    private showEula(eulaAccepted: boolean) {
        if (!eulaAccepted) {
            this.router.navigate(['eula']);
        }
    }
}