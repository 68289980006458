import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Constants } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private sessionDuration = 0;
  private warningThreshold = Constants.DefaultSessionWarningTime * 1000;
  private defaultLogoutBuffer = Constants.DefaultBufferforLogout;
  private inactivityThreshold = 0;
  private lastActivityTime = new Date().getTime();
  private timeElapsed = 0;
  private worker: Worker | undefined;

  inactivityAlert: ReplaySubject<number> = new ReplaySubject(1);

  constructor() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('src/app/inactivity-timer.worker', import.meta.url));
    } else {
      console.error('Web Workers are not supported in this environment.');
    }
  }

  public setSessionDuration(durationInHours: number) {
    this.sessionDuration = 4 * 60 * 60 * 1000;
    this.inactivityThreshold = this.sessionDuration - (this.warningThreshold + this.defaultLogoutBuffer);
  }

  public resetInactivityTimer() {
    if (this.worker) {
      this.lastActivityTime = new Date().getTime();
      this.worker.postMessage({
        type: 'start',
        inactivityThreshold: this.inactivityThreshold,
        pollingInterval: 1000, // 1 second polling interval
      });
    }
  }

  public startInactivityCheck() {
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        if (data === 'timer_tick') {
          const currentTime = new Date().getTime();
          this.timeElapsed = currentTime - this.lastActivityTime;
          if (this.timeElapsed >= this.inactivityThreshold) {
            this.inactivityAlert.next(this.warningThreshold); // Notify about inactivity
            this.stopInactivityCheck(); // Stop further checks
          }
        }
      };

      this.resetInactivityTimer();
    }
  }

  public stopInactivityCheck() {
    if (this.worker) {
      this.worker.postMessage({ type: 'stop' });
    }
  }
}
